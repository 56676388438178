type TLabelText = {
  label: string
  value: string
}

const LabelText = ({ label, value }: TLabelText) => (
  <div className="mt-6 grid grid-cols-3">
    <div className="text-sm font-normal text-tertiary300">{label || "-"}</div>
    <div className="col-span-2 text-sm font-semibold text-tertiary500">
      : {value || "-"}
    </div>
  </div>
)

export default LabelText
