"use client"

import { useState } from "react"
import dynamic from "next/dynamic"
import { ChevronRight } from "react-feather"
import { Spinner } from "shared-ui"
import SellerProfileEmptyState from "./SellerProfileEmptyState"
import capitalize from "@/utils/capitalize"

import useGetSellerScore from "../hooks/useGetSellerScore"
import { SellerScoreDetail } from "@/federatedGql/graphql"
import { defaultSellerScoreDetail } from "../constant"

type TSellerScoreSectionProps = {
  sellerId: string
}

const ModalSellerScoreDetail = dynamic(() => import("./ModalSellerScoreDetail"))
const ModalSellerParameterDetail = dynamic(
  () => import("./ModalSellerParameterDetail")
)

const SellerScoreSection = ({ sellerId }: TSellerScoreSectionProps) => {
  const { data: sellerScore, isFetching: isFetchingSellerScore } =
    useGetSellerScore({
      variables: {
        input: {
          sellerId,
        },
      },
    })

  const [isOpenModalDetail, setIsOpenModalDetail] = useState<boolean>(false)
  const [isOpenModalParameterDetail, setIsOpenModalParameterDetail] =
    useState<boolean>(false)
  const [selectedSellerScoreDetail, setSelectedSellerScoreDetail] =
    useState<SellerScoreDetail | null>(null)

  const handleClickParameterDetail = (scoreDetail: SellerScoreDetail) => {
    setSelectedSellerScoreDetail(scoreDetail)
    setIsOpenModalParameterDetail((prev) => !prev)
  }

  const capitalizeComponentName = (name: string) => {
    return name
      .split(" ")
      .map((word) => (word === "PPK:" ? word : capitalize(word)))
      .join(" ")
  }

  const sellerScoreDetail = sellerScore?.details.length
    ? sellerScore.details
    : defaultSellerScoreDetail

  return (
    <div className="flex flex-col">
      <div className="text-heading-h6">Penilaian Penyedia</div>
      {isFetchingSellerScore && (
        <div className="flex justify-center">
          <Spinner size="medium" color="primary" />
        </div>
      )}
      {!sellerScore && !isFetchingSellerScore && (
        <SellerProfileEmptyState
          title="Mohon Maaf, Terjadi Kesalahan"
          description="Mohon ulangi dalam beberapa saat lagi"
        />
      )}
      {sellerScore && (
        <div className="mb-6 mt-4 flex w-full rounded-4 border border-tertiary50">
          <div className="flex w-[250px] flex-col items-center justify-center border-r border-r-tertiary50">
            <div className="text-body-sm-semobold text-tertiary300">
              Total Nilai Penyedia
            </div>
            <div>
              <span className="text-heading-h3 text-tertiary500">
                {sellerScore.finalScore ?? "-"}
              </span>
              <span className="text-2xl text-tertiary300">/100</span>
            </div>
            <div>
              <button
                className="text-caption-lg-semibold text-secondary500"
                onClick={() => setIsOpenModalDetail((prev) => !prev)}
              >
                Rincian Penilaian
              </button>
            </div>
          </div>

          <div className="flex w-[calc(100%-250px)] flex-col p-6">
            <div className="text-body-sm-semibold mb-2 flex w-full border-b border-b-tertiary50 pb-8 text-tertiary500">
              <div className="w-2/3">Parameter Penilaian</div>
              <div className="w-1/3">Performa</div>
            </div>

            {sellerScoreDetail.map((item) => {
              return (
                <div
                  className="flex rounded-4 px-4 py-3 hover:bg-secondary50"
                  key={`seller-valuation-${item.componentName}`}
                >
                  <div className="text-caption-lg-regular w-2/3 text-tertiary300">
                    {capitalizeComponentName(item.componentName ?? "")}
                  </div>
                  <div className="text-caption-lg-semibold flex w-1/3 items-center justify-between text-tertiary500">
                    <div className="pl-6">
                      {item.value
                        ? `${item.value}${item.valueUnit ?? ""}`
                        : "-"}
                    </div>
                    <div className="mt-1">
                      <button onClick={() => handleClickParameterDetail(item)}>
                        <ChevronRight
                          size={14}
                          className="cursor-pointer text-tertiary500"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <ModalSellerScoreDetail
        open={isOpenModalDetail}
        sellerScore={sellerScore}
        onOpenChange={() => setIsOpenModalDetail((prev) => !prev)}
      />
      <ModalSellerParameterDetail
        open={isOpenModalParameterDetail}
        onOpenChange={() => setIsOpenModalParameterDetail((prev) => !prev)}
        sellerScoreDetail={selectedSellerScoreDetail}
      />
    </div>
  )
}

export default SellerScoreSection
