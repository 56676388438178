"use client"

import React from "react"

import LabelText from "./LabelText"
import SellerScoreSection from "./SellerScoreSection"
import type { CompanyPublicResult } from "@/federatedGql/graphql"

type TProfilPenyediaSection = {
  companyData: CompanyPublicResult
  isAuthenticated: boolean
  isRatingReviewEnabled?: boolean
  sellerId: string
}

const ProfilPenyediaSection = ({
  companyData,
  isAuthenticated,
  isRatingReviewEnabled,
  sellerId,
}: TProfilPenyediaSection) => {
  const company = companyData.companies?.[0]

  return (
    <div className="w-full">
      {company && (
        <>
          <div className="mb-6 mt-4 rounded-4 border border-tertiary50 p-8">
            <div className="text-xl font-bold">Informasi Perusahaan</div>
            <div className="flex flex-row text-sm">
              <div className="basis-1/2 gap-4">
                <LabelText
                  label="Nama Perusahaan"
                  value={company?.name || "-"}
                />
                <LabelText
                  label="Bentuk Usaha"
                  value={company?.bentukUsaha?.replace(/_/g, " ") || "-"}
                />
                <LabelText
                  label="Status Penyedia"
                  value={company?.status === "active" ? "Aktif" : "Tidak Aktif"}
                />
                <div className="mt-6 grid grid-cols-3">
                  <div className="text-sm font-normal text-tertiary300">
                    Tipe Penyedia
                  </div>
                  <div className="col-span-2 text-sm font-semibold text-tertiary500">
                    :
                    <span className="ml-1 rounded-4 bg-tertiary25 px-2 py-1">
                      {company?.isUmkk ? "UMKK" : "Non UMKK"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="basis-1/2 gap-4">
                {isAuthenticated && (
                  <LabelText
                    label="Alamat Perusahaan"
                    value={company?.companyAddresses[0]?.fullAddress || "-"}
                  />
                )}
                <LabelText
                  label="Kode Pos"
                  value={company?.companyAddresses[0]?.postalCode || "-"}
                />
                <LabelText
                  label="Provinsi"
                  value={
                    company?.companyAddresses[0]?.regionDetail?.provinceName ||
                    "-"
                  }
                />
                <LabelText label="Situs Web" value={company?.website || "-"} />
              </div>
            </div>
          </div>
          {isRatingReviewEnabled && <SellerScoreSection sellerId={sellerId} />}
        </>
      )}
    </div>
  )
}

export default ProfilPenyediaSection
