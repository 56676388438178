type TEmptyStateProps = {
  title: string
  description: string
}

const SellerProfileEmptyState = ({ title, description }: TEmptyStateProps) => {
  return (
    <section className="my-4 w-full rounded-3 border border-tertiary50 bg-tertiary25">
      <div className="flex h-[250px] flex-col items-center justify-center">
        <div className="mb-4 text-lg font-bold text-tertiary500">{title}</div>
        <div className="text-base font-normal text-tertiary300">
          {description}
        </div>
      </div>
    </section>
  )
}

export default SellerProfileEmptyState
